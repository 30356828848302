const ɵ0 = sub => +sub || sub;
export const environment = {
    production: true,
    BASE_URL: './cosiqa',
    ORELY_URL: './orely',
    TOKEN_NAME: 'demo-platform-token',
    FILE_SIZE_LIMIT: 10240000000,
    SUB_FORMATTER: ɵ0
};
export { ɵ0 };
